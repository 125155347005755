import { Box, Rating, Typography, styled,Alert } from '@mui/material';
import {  useInput, useTranslate } from 'react-admin';

export const StarRatingInput = (props) => {
    const { name = 'resources.seats.fields.review' } = props;
    const { field,fieldState } = useInput(props);
    const translate = useTranslate();

    return (
        <Box display="flex" flexDirection="column" marginBottom="1rem">
            <Typography>Please indicate how much you enjoyed your seat:</Typography>
            <StyledRating
                sx={{m:2}}
                {...field}
                max={3}
                onChange={(event, value) => field.onChange(value)}
            />
            {fieldState.error && <Alert severity="error">You cannot select 0 ★ </Alert>}
            <Typography>3★ = you would like to qualify into this practice area</Typography>
            <Typography>2★ = you would consider qualifying into this practice area</Typography> 
            <Typography>1★ = you would not like to qualify into this practice area</Typography>
        </Box>
    );
};

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#000',
    },
    '& .MuiRating-iconHover': {
        color: '#000',
    }
});

