import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    usePermissions,
    FunctionField,
    TextInput,
    TextField
} from "react-admin";
import { PostPagination } from './Trainee';


export const GroupsList = (props) => {

    const { permissions } = usePermissions();
    return (
        <List {...props} exporter={false} pagination={<PostPagination />} >
            <Datagrid bulkActionButtons={permissions?.includes("Admins") ? true : false} sx={{
                '& .column-startDate': { textAlign: 'left' },
            }} >
                <TextField source="name" label="Group" />
                {permissions?.includes("Admins") && (<EditButton />)}
            </Datagrid>
        </List>
    );
};

export const GroupsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm defaultValues={{ type: "Groups" }}>
            <TextInput source="name" label="name"/>
        </SimpleForm>
    </Edit>
);

export const GroupsCreate = (props) => (
    <Create {...props} >
        <SimpleForm defaultValues={{ type: "Groups" }}>
        <TextInput source="name" label="name"/>
        </SimpleForm>
    </Create>
);

