import '@aws-amplify/ui-react/styles.css';
import {
    List,
    Datagrid,
    SimpleForm,
    Create,
    Edit,
    EditButton,
    TextInput,
    BooleanInput,
    ReferenceField,
    ReferenceInput,
    useGetOne,
    useRecordContext
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import { PostPagination } from './Trainee';


export const TraineeNameField = (props) =>
{
    const record = useRecordContext(props);

    
    var id = record.traineeID
    if (!(id?.includes("::"))) {
        id = id + "::" + id
    }
    const {data, isLoading} = useGetOne("trainees", {id})
    if (isLoading) {
        return (<></>)
    }
    return (<div>{data?.name}</div>)
}

export const TraineeCommentsList = (props) => {

    return (
        <List {...props} exporter={false} pagination={<PostPagination />}>
            <Datagrid >
                <ReferenceField source="rotationID" reference='rotations'/>
                <TraineeNameField/>
                <EditButton />
            </Datagrid>
        </List>
    );
};

export const TraineeCommentsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm >
            <RichTextInput source="comment" /> 
        </SimpleForm>
    </Edit>
);

export const TraineeCommentsCreate = (props) => (
    <Create {...props} >
        <SimpleForm >
            <ReferenceInput source="traineeID" reference="trainees" perPage={1000} />
            <ReferenceInput source="rotationID" reference='rotations'/>
        <RichTextInput source="comment" /> 

        </SimpleForm>
    </Create>
);
