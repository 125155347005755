import { defaultTheme } from 'react-admin';

export const Theme = {
  ...defaultTheme,
  palette: {
    secondary: { main: "#11111f" },
  },
  typography: {
    fontSize: 14,
  },
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-rowCell": {
            textAlign: 'center',
          },
          "& .RaDatagrid-headerCell": {
            textAlign: 'center',
            fontWeight: 'bold',
          }
        }
      }
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          "& .RaLabeled-label": {
            fontSize: "12pt"
          },
          "& .RaSimpleShowLayout-stack": {
            "& .RaLabeled-label": {
              fontSize: "12pt"
            }
          }
        },
      }
    }
  }
};
